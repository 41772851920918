// Dependencies
import React from 'react';
import { useToggleUtil } from "../utils/useUtil";
import ClassCard from './ClassCard';

// Component
import Loader from 'react-loader-spinner';

// Style
import "../styles/Tab.css";

export const SimpleTabsComponent = ({style}) => {
  const [tabs, activeTab, setActiveTab] = useToggleUtil("tabs", 1, style);

  if (tabs.length < 1) return <Loader type='Bars' color='#000000' height={200} width={200} timeout={5000}/>;

  const createTabs = () =>
    tabs.map(tab => (
      <button
        key={`${tab.id}-tab`}
        role="tab"
        aria-selected={tab.id === activeTab}
        id={`${tab.id}-panel`}
        aria-controls={`${tab.id}-content-panel`}
        onClick={() => setActiveTab(tab.id, "tabs")}
        className={tab.id === activeTab ? "tab tab--selected" : "tab"}
      >
        {tab.title}
      </button>
    ));

  const createTabPanels = () =>
    tabs.map(tab => (
      <div
        key={`${tab.id}-panel`}
        id={`${tab.id}-content-panel`}
        role="tabpanel"
        aria-labelledby={`${tab.id}-tab`}
        className={`tab__panel ${tab.id === activeTab ? "show" : "hide"}`}
      >
        <div className='grid'>
          {tab.content.map(e => {
            return ( <ClassCard klass={e} key={e.id}/> )
          })}
        </div>
      </div>
    ));

  return (
    <article className='tabContainer'>
      <div className='tabList' role="tablist" aria-orientation="horizontal">
        {createTabs()}
      </div>
      {createTabPanels()}
    </article>
  );
};

export default SimpleTabsComponent;