export const Managers = [
  {id: 0, },
  {id: 1, },
  {id: 2, }
]

// Sun 日曜
// Mon 月曜
// Tue 火曜
// Wed 水曜
// Thu 木曜
// Fri 金曜
// Sat 土曜


// お問合せ
