// Dependencies
import React, {useState} from 'react';

// Style
import '../styles/ClassCard.css';

// Component
import Payment from './modals/PayForm';

const ClassCard = ({klass}) => {
  let [show, setShow] = useState(false); // default is set to hide
  let [modal, setModal] = useState({title:'', time: '', end: ''}); // event info for modal

  // Toggle handler
  const toggleEvent = e => {
    // If show is false add the data before opening the modal, else close the modal without changing the data
    if(!show){ setModal(e) }
    setShow(!show);
  }

  return(
    <div className='class_cardContainer'>
      {window.innerWidth < 700 ? 
        <img onClick={(event => toggleEvent(klass))} className='class_image' src={klass.pic} alt={klass.title}/>
      :
        <img onClick={(event => toggleEvent(klass))} className='class_image' src={klass.mobile_pic} alt={klass.title}/>
      }

      <div className='class_info'>
        <h4>{klass.level}</h4>
        <p>{klass.day} {klass.time}-{klass.end}</p>
        <button className='class_btn' onClick={(event => toggleEvent(klass))}>質問・予約</button>
      </div>
      <Payment handleClose={toggleEvent} show={show} classInfo={modal} />
    </div>
  );
};

export default ClassCard;