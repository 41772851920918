// Dependencies
import React from 'react';

// Import styling for the overall app
import './styles/App.css'; // This file will be the CSS reset// Styling

// Components
import NavBar from './components/Navigation/NavBar'
import Routes from './routes/Routes';
import { TopBtn } from "./utils/backToTop";


function App() {
  const navRef = React.createRef();
  const buttonRef = React.createRef();


  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function() {scrollFunction()};

  function scrollFunction() {
    // top button
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
      buttonRef.current.style.display = "flex";
    } else {
      buttonRef.current.style.display = "none";
    }
    // navbar
    if(document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      navRef.current.style.backgroundColor = "black";
    } else {
      navRef.current.style.backgroundColor = "black";
      // navRef.current.style.backgroundColor = "rgb(0,0,0,0)";
    }
  }

  console.log(navigator.language)

  return (
    <div className="App">
      <header>
        <NavBar ref={navRef}/>
      </header>
      <div className="App_Content">
        <Routes/>
        <TopBtn ref={buttonRef}/>
      </div>
      <footer>
        
      </footer>
    </div>
  );
};

export default App;
