// Dependencies 
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';

// Components
import { GetStaff } from '../services/StaffService';
import StaffCard from '../components/Staff/StaffCard';

// Style
import '../styles/StaffView.css';

const StaffList = function() {
  let [staff, setStaff] = useState();
  let [loading, setLoading] = useState(true);

  useEffect( () => {
    async function grab() {
      const data = await GetStaff()
      setStaff(data)
      setLoading(false)
    }

    grab()
  }, []);

  return (
    <div className='staff_container'>
      <div className='staff_topSect'>
        <h2 className='staff_title'> 講師 NXGN TEACHERS </h2>
        <p className='staff_pageDesc'>世界で活躍するシーボが認めた、ネクスト・ジェネレーションが誇る素晴らしい講師陣です！ストリートダンスからジャズまで様々なジャンルで活躍中の講師達のレッスンは楽しくて為になりますよ♫ <br/>
          <br/>出演依頼、振付、プライベートレッスンなども受け付けています。
        </p>
      </div>
      { loading 
        ? (<div className='staff_spinner'>
            <Loader type='Bars' color='#000000' height={200} width={200} timeout={5000}/>
          </div>
        )
        : (<div className='staff_grid'>
            {staff && staff.map(e => {
              return <StaffCard ins = {e} key = {e.id}/>
            })}
          </div>
        )
      }
    </div>
  );
};

export default StaffList;