// Dependencies
import React from 'react';
// import { Link } from 'react-router-dom';

// Style
import '../../styles/ManagementCard.css';

const ManagementCard = ({profile}) => {
  return (
    <div>
      
    </div>
  )
}

export default ManagementCard