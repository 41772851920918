// Dependencies
import React, { useState } from 'react';
import axios from "axios";

// Style 
import '../styles/ContactPage.css';


const Contact = () => {
  let [user, setUser] = useState({
    name: '',
    email: '',
    message: ''
  });
  const handleChanges = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log('attempt')
    if( user.name && user.email ){
      axios
      .post('https://nxgn-studios.herokuapp.com/api/mail/support', user)
      .then( res => {
        console.log(res)
        resetForm()
      })
      .catch( err => {
        console.log(err)
      })
    }
  };

  const resetForm = () => {
    setUser({ name: '', email: '', message: ''})
  };

  return (
    <div className='contact_container'>
      <div className="contact_content">
        {/* <h2>Contact Us</h2> */}
        <h2>お問い合わせ</h2>
        <div className="divider" style={{margin: 0}}/>

        {/* Phone Section */}

        <div className='contact_phone'>
          <h3>お電話でのお問い合わせ</h3>
          <div className="contact_divider"/>
          {/* <p className='contact_note'>Thank you for visiting our site if there are any questions you have please feel free to consult or FAQ page or contact us by using the link below.</p> */}
          <p className='contact_note'>お問い合わせはお電話またはメールにて、お気軽にお問い合わせください。</p>
          <p><span className='bold'>TEL:</span> 086.226.2717
          <span className='subtext'><br/>※つながらない場合は、留守番電話にお名前とお電話番号を残していただくか、<br />メールにてお問い合わせください。<br /></span>
          </p>
        </div>

        {/* Email Section */}

        <div className='contact_email'>
          <h3>メールでのお問い合わせ</h3>
          <div className='contact_divider' />
          <p><span className='bold'>EMAIL:</span> info@NXGNstudios.com または nxgnNYstudios@icloud.com
          <span className='subtext'><br />※3日以内に返信が無い場合は、メールアドレスがブロックされている可能性がございます。</span></p>
        </div>

        {/* Form Section */}

        <div className='contact_send'>
          <h3>お問い合わせフォームでのお問い合わせ</h3>
          <div className='contact_divider'/>
          <div className='contact_form'>
            <div className='contact_input_split'>
              <div className="contact_input_title">
                {/* <h3 htmlFor="form_name">Name<span className='bold'>*</span></h3> */}
                <h4 className='contact_title' htmlFor="form_name">お名前<span className='bold'>※</span></h4>
              </div>
              <input onChange={handleChanges} name="name" className="contact_input_field" type="text" />
            </div>
            <div className='contact_input_split'>
              <div className="contact_input_title">
                {/* <h4 className='contact_title' htmlFor="form_email">Email<span className='bold'>*</span></h4> */}
                <h4 className='contact_title' htmlFor="form_email">メールアドレス<span className='bold'>※</span></h4>
              </div>
              <input onChange={handleChanges} name="email" className="contact_input_field" type="text" />
            </div>
            <div className='contact_input_split'>
              <div className="contact_input_title">
                {/* <h4 className='contact_title' htmlFor="form_message">Message<span className='bold'>*</span></h4>						 */}
                <h4 className='contact_title' htmlFor="form_message">お問い合わせ内容<span className='bold'>※</span></h4>						
              </div>
              <textarea onChange={handleChanges} className='contact_input_field' name="message" rows="9" cols="20"></textarea>
            </div>
          </div>
          {/* <input className="contact_submit contact_field" type="submit" name="submit" value="Send Message" />				 */}
          <button onClick={handleSubmit} className="contact_submit">"送信"</button>		
        </div>		
      </div>
    </div>
  );
};

export default Contact;