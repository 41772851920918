// Dependencies
import { useState, useEffect } from "react";

// Component
import { GetClasses, GetByStyle } from '../services/ClassService';

// SelectedItem is the default of when the page loads
export const useToggleUtil = (type, selectedItem, style=null) => {
  const [activeItem, setActiveItem] = useState(selectedItem);
  const [items, setItems] = useState([]);

  // Create the tab setup since the days of the week don't change(this is static data)
  const titles = [
    {
      "id": 1,
      "title": 'All',
      "content": []
    },
    {
      "id": 2,
      "title": 'Sun 日曜',
      "content": []
    },
    {
      "id": 3,
      "title": 'Mon 月曜',
      "content": []
    },
    {
      "id": 4,
      "title": 'Tue 火曜',
      "content": []
    },
    {
      "id": 5,
      "title": 'Wed 水曜',
      "content": []
    },
    {
      "id": 6,
      "title": 'Thu 木曜',
      "content": []
    },
    {
      "id": 7,
      "title": 'Fri 金曜',
      "content": []
    },
    {
      "id": 8,
      "title": 'Sat 土曜',
      "content": []
    },
  ];

  const changeActiveItem = id => {
    // True or false statements based on equivalencies
    const isTabs = type === "tabs";
    const isAlreadyActive = activeItem === id;
    const idSelected = isAlreadyActive && !isTabs ? 0 : id;
    setActiveItem(idSelected);
  };

  useEffect(() => {
    let data;
    const fetchData = async () => {
      // Check if a style was selected in the drop down menu, if so then only grab those classes
      if(style && style !== 'All') {
        data = await GetByStyle(style.toLowerCase().split(' ').join('_'));
        // console.log(style.toLowerCase().split(' ').join('_'))
        // console.log(data)
      }
      else { 
        data = await GetClasses();
      }

      titles.forEach(e => {
        // If the tab is all then insert all returned classes
        if(e.title.includes('All')) { e.content = data }
        // Otherwise grab only the classes for that day of the week
        else {
          data.forEach(c => {
            if(c.day.includes(e.title.split(' ')[0])){ e.content.push(c) }
          })
        }
      });
      setItems(titles);
    };
    fetchData();
  }, [style]);

  return [items, activeItem, changeActiveItem];
};