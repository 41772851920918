// Dependencies
import React from 'react';
import { Link } from 'react-router-dom';

// Style
import '../../styles/StaffCard.css';

const StaffCard = ({ins}) => {
  let shortened = ins.bio;
  if(ins.bio.length > 40) {
    shortened = ins.bio.substr(0, 50) + '...'
  }

  return(
    <div className='staff_cardContainer'>
      <Link to={{pathname: `/staff/${ins.id}`, state: { ins }}}><img className='staff_image' src={ins.pic} alt={ins.fName}/></Link>

      <div className='staff_info'>
        <div>
          <Link to={{pathname: `/staff/${ins.id}`, state: { ins }}}><h3>{ins.fName}</h3></Link>
          <h4>{ins.style}</h4>
          <p>{shortened}</p>
        </div>
        <a className='staff_media' href={`https://www.instagram.com/${ins.socialMedia}`}>@{ins.socialMedia}</a>
      </div>
    </div>
  );
};

export default StaffCard;