// Import Dependencies
import React, { useState } from 'react';
import axios from 'axios';

// Style
import './ModalStyle.css';

const Payment = ({ handleClose, show, classInfo }) => {
  // Display only if show is true
  const showHide = show ? {display: 'block'} : {display: 'none'};
  let [submitted, setSubmitted] = useState(false);
  let [user, setUser] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const handleChanges = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    if( user.name && user.email ){
      axios
      .post('https://nxgn-studios.herokuapp.com/api/mail', user)
      .then( res => {
        console.log(res)
        resetForm()
        setSubmitted(true)
      })
      .catch( err => {
        console.log(err)
      })
    }
  }

  const handleCancel = e => {
    setSubmitted(false);
    handleClose();
  }

  const resetForm = () => {
    setUser({ name: '', phone: '', email: '', message: ''});
  }

  return (
    <div className='modalContainer' style = {showHide}>
      <div className='modalMain'>
        <>
          <h2>Class: {classInfo.title}</h2>
          <h2>Time: {classInfo.time} - {classInfo.end}</h2>
        </>
        {!submitted ?
          <div className='modalForm'>
            <div className='modalInput'>
              <p>Name: </p>
              <input autoFocus={true} required name='name' value={user.name} onChange={handleChanges} placeholder='John Doe' type='text'/>
            </div>
            <div className='modalInput'>
              <p>Phone: </p>
              <input name='phone' value={user.phone} onChange={handleChanges} placeholder='123-456-7890' type='number'/>
            </div>
            <div className='modalInput'>
              <p>Email: </p>
              <input required name='email' value={user.email} onChange={handleChanges} placeholder='John@Gmail.com' type='email'/>
            </div>
            <div className='modalInput textarea'>
              <p>Message: </p>
              <textarea wrap='hard' rows='3' cols='33' name='message' value={user.message} onChange={handleChanges} placeholder={`お問い合わせ内容 \nQuestions/Comments/Concerns`}/>
            </div>
            <div className='modalBtn'>
              <button onClick={handleClose}>Cancel</button>
              <button onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        :
          <div className='modalBuy'>
            <a href='https://www.paypal.me/nxgnnystudios'>Pay for the Class Here</a>
            <button onClick={handleCancel}>Close</button>
          </div>
        }
      </div>
      
    </div>
  );
};

export default Payment;
