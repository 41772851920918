// Dependency
import axios from 'axios';
const domain = process.env.REACT_APP_DOMAIN || 'https://nxgn-studios.herokuapp.com';

// Set Base for all API calls in this file
const classAPI = axios.create({
  baseURL: domain,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

// Get All
export const GetClasses = async () => {
  try {
    const data = await classAPI.get('/api/class')
    .then(res => {
      return res.data;
    })
    return data;
  }
  catch(err) {
    console.log(err);
  }
};

// Get By ID
export const GetClass = async (id) => {
  try {
    const data = await classAPI.get(`/api/class/${id}`)
    .then(res => {
      return res.data;
    })
    return data;
  }
  catch(err) {
    console.log(err);
  }
};

// Get List of Classes
export const GetByStyle = async (name) => {
  try {
    const data = await classAPI.get(`/api/class/style/${name}`)
    .then(res => {
      return res.data;
    })
    return data;
  }
  catch(err) {
    console.log(err);
  }
};

export default { GetClasses, GetClass, GetByStyle };