// Dependencies
import React from 'react';

// Style 
import '../styles/FeePage.css';

const FeePage = () => {

  return(
    <>
      {/* <img src='./PAGE_IMAGES/Untitled-4.jpg' className='landingImage'></img> */}
      <div className="fee_content">
          <h2>Find the plan that fits you</h2>
          <div className='divider'/>
          <p>Here at NXGN NY Studios we are always trying to find the most affordable way to have dance as a part of your life. We also understand that commitment is essential to the growth of any dancer. Below we believe we have come up with a suitable solution to being able to afford class and being able to come to class as scheduling permits. Please review the pricing below and if you have any questions please contact us using the the contact form or email us directly at info@nxgnstudios.com. or call us at 086.232.2185. Thank you for your time and we look forward to seeing you in class. </p>
        <div className="fee_membership">
          <h3>Membership &amp; Lesson Fees</h3>
          <div className='divider'/>
          <table cellPadding="0" cellSpacing="0" className="fee_table">
            <thead>
              <tr>
                <td className='fee_row'>
                  <h4>Membership Fee</h4>
                  <span className="fee_japanese">入会金3,000円</span>
                </td>
                <td className='fee_row'>
                  <h4>Class Tickets</h4>
                  <span className="fee_japanese">受講料</span>
                </td>
                <td className='fee_row'>
                  <h4>Member Fees</h4>
                  <span className="fee_japanese">メンバー</span>
                </td>
                <td className='fee_row'>
                  <h4>Visitor fees</h4>
                  <span className="fee_japanese">ビジター</span>
                </td>
                <td className='fee_row'>
                  <h4>Instructions</h4>
                  <span className="fee_japanese">備考</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan="2" className='fee_rowAlt'>
                  <h4>Adults</h4><span className='fee_japaneseHeader'>大人</span>
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">１回</span>/1Class
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">1,900円</span>
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">2,200円</span>
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">見学無料。<br />体験はビジター料金です。</span>
                </td>
              </tr>
              <tr>
                <td className='fee_row'>
                  <span className="fee_prices">10回チケット</span>/10ClassTicket
                </td>
                <td className='fee_row'>
                  <span className="fee_prices">17,000円</span>
                </td>
                <td className='fee_row'>&nbsp;</td>
                <td className='fee_row'>
                  <span className="fee_prices">３ヶ月間有効。お得です。</span>
                </td>
              </tr>
              <tr>
                <td rowSpan="3" className='fee_rowAlt'>
                  <h4>Kids</h4><span className='fee_japaneseHeader'>学生</span>
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">１回</span>/1Class
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">1,700円</span>
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">2,000円</span>
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">入会時に学生証を提示下さい。</span>
                </td>
              </tr>
              <tr>
                <td className='fee_row'>
                  <p className="fee_prices">月謝(週１コース)<br />Monthly Class Payment</p>
                </td>
                <td className='fee_row'>
                  <span className="fee_prices">週数x1,500円</span>
                </td>
                <td className='fee_row'>&nbsp;</td>
                <td className='fee_row'>
                  <span className="fee_prices">週数はスタジオで決定しています。</span>
                </td>
              </tr>
              <tr>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">10回チケット</span>/10Class Ticket
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">15,000円</span>
                </td>
                <td className='fee_rowAlt'>&nbsp;</td>
                <td className='fee_rowAlt'>
                  <span className="fee_prices">３ヶ月有効。お得です。</span>
                </td>
              </tr>					
              <tr>
                <td className='fee_row'>&nbsp;</td>
                <td className='fee_row'>&nbsp;</td>
                <td className='fee_row'>&nbsp;</td>
                <td className='fee_row'>&nbsp;</td>
                <td className='fee_row'>
                  <a href="/schedule" className="fee_btn">Sign Up!</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>Studio Rental Fees</h3>
        <div className='divider'/>
        <div className="fee_studio">
          <table cellPadding="0" cellSpacing="0" className="fee_table">
            <thead>
              <tr>
                <td className='fee_row'>
                  <h4>Hourly Studio Rental</h4>
                  <span className="fee_japanese">スタジオレンタル60分毎</span>
                </td>
                <td className='fee_row'>
                  <h4>Studio A Small</h4>
                  <span className="fee_japanese">スタジオA (小)</span>
                </td>
                <td className='fee_row'>
                  <h4>Studio B Large</h4>
                  <span className="fee_japanese">スタジオB (大)</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='fee_rowAlt'>
                  <h4>Members Hourly Fee</h4>
                  <span className='fee_japaneseHeader'>メンバー練習利用</span>
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_studioPrices">1人300円/1person 300yen</span> per hour
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_studioPrices">1人400円/1person 400yen per hour</span>
                </td>
              </tr>
              <tr>
                <td className='fee_row'>
                  <h4>Visitor Hourly Fee</h4>
                  <span className='fee_japaneseHeader'>ビジター練習利用</span>
                </td>
                <td className='fee_row'>
                  <span className="fee_studioPrices">1人400円/1person 400 yen per hour</span>
                </td>
                <td className='fee_row'>
                  <span className="fee_studioPrices">1人500円/1person 500yen per hour</span>
                </td>
              </tr>
              <tr>
                <td height="106" className='fee_rowAlt'>
                  <h4>Visitor Hourly Fee</h4>
                  <span className='fee_japaneseHeader'>商業利用</span>
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_studioPrices">1,800円</span>
                </td>
                <td className='fee_rowAlt'>
                  <span className="fee_studioPrices">2,500円</span>
                </td>
              </tr>
              <tr>
                <td className='fee_row'>&nbsp;</td>
                <td className='fee_row'>&nbsp;</td>
                <td className='fee_row'>
                  <a href="#" className="fee_btn">Book!</a> {/* onclick to open a modal for studio rentals*/}
                </td>
              </tr>
            </tbody>
          </table>
          <h2>Studio Layout</h2>
          <div className="divider"/>
          <img src="./assets/floorplan.png" alt="layout of studio" width="460" height="460" className="fee_image" />
        </div>		
      </div>
    </>
  );
};

export default FeePage;