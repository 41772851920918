// Dependencies 
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';

// Component
import SimpleTabsComponent from '../components/Tab';
import { GetStyles } from '../services/StyleService';

// Style
import '../styles/Schedule.css';

const Schedule = function() {
  let [styles, setStyles] = useState([]);
  let [send, setSend] = useState('All');
  let [select] = useState(React.createRef());
  let [loading, setLoading] = useState(true);

  useEffect( () => {
    const grab = async () => {
      let list = [];
      const data = await GetStyles();
      data.forEach(e => {
        let first = e.style.split('_'), name = [];
        first.forEach( e => { 
          name.push((e.split('')[0].toUpperCase() + e.split('').splice(1, 9).join('')));
        });
        list.push(name.join(' '));
      });
      setStyles(list);
      setLoading(false);
    };

    grab();
  }, []);

  const handleChange = () => {
    setSend(select.current.value);
  };

  return (
    <>
      {loading 
        ? (<div className='staff_spinner'>
            <Loader type='Bars' color='#000000' height={200} width={200} timeout={5000}/>
          </div>
        )
        : (<div className='sched_container'>
            <div className='sched_top'>
              <h2>Style: </h2>
              <select className='sched_dropdown' ref={select} onChange={handleChange}>
                <option value='All'>All</option>
                {styles.map( (e, i) => {
                  return <option key={i} value={e}>{e}</option>
                })}
              </select>
            </div>
            <SimpleTabsComponent style={send} />
          </div>
        )
      }
    </>
  );
};

export default Schedule;