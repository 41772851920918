// Dependencies
import React from 'react';
import { Link } from 'react-router-dom';

// Style
import '../styles/AboutPage.css';

// Data
const jpDesc = 'NEXT GENERATION (NXGN NY) は1999年にダンスチームとしてニューヨークで結成されました。その後スタジオ創立者であるシーボが初めて岡山を訪れた時、人々の人柄に惹かれ、生きた情報が届き難い岡山にニューヨークからの情報をどんどん取り入れて地元の人が地方でも自信を持ってダンスを踊れる環境を作ってシーンを盛り上げようと、2005年9月3日に岡山のスタジオをオープンしました。\n \n ストリートダンスの文化と楽しさを伝えることに務めるだけでなく、国際文化への意識向上と交流を重視。ダンスを通した交流で人生を豊かにすることを目的とし、素晴らしい講師陣のもと、年齢・性別・人種は関係なくお互いを尊重しあい、お互いの個性や違いを認め合いながら楽しくダンスができる環境を提供しています。また、NEXT GENERATION＝次世代という名前の通り、次の世代へとこの文化を伝え広めて行く活動にも力を入れています。\n \n これまでに、ダンス文化を重視した公演を多数開催。ストリート系ダンスのスタジオには無かった月間スケジュール表の発行など、細やかなサービスで質の向上を図り、常に市民のストリートダンスへの理解と意識向上の為に努めてきました。これらの活動を認められ、シーボが県内の次世代リーダーを賞する「おかやまアワード」受賞。県内のストリートダンス・スタジオとしてはいち早く、国民文化祭で岡山県の後援を受けたダンスイベントの開催、岡山市芸術祭や財団などから活動をご支持いただいてきました。\n 今後もダンスの楽しさを伝えながら、更に文化への意識向上と国際交流の為に努めます。'

// const enDesc = 'Welcome and thank you for your interest in Next Generation New York Studios Japan (NXGN NY Studios). Since 2005 NXGN NY Studios has grown to become one of the best dance studios in the Chugoku area of Japan. NXGN NY Studios has built a reputation for providing dance training and culutral awareness in the community. NXGN is also known for the wide array of dance disciplines available as well as the rotating guest lectures by some of the worlds top dance professionals. NXGN NY Studios continues to work with the community to build a stronger arts awareness in western Japan. Located in directly in center of Okayama city making it available to dancers from all around Asia.'

const AboutPage = () => {
  return (
    <div className='about_container'>
      <h2>About NXGN NY Studios Japan</h2>
      <div className='divider'/>
      
      {/* Top Section */}
      
      <div className='about_top'>
        <img src='./assets/studio2.png' alt='dance studio' />
        {/* <p>{navigator.language.includes('en') ? enDesc : jpDesc}</p> */}
        <p>{jpDesc}</p>
      </div>
      <div className='divider'/>
      
      {/* Bottom Section */}
      <div className='about_bottom'>
        <div className='about_info'>
          <h3>Studio Programs</h3>
          <p>NXGN NY Studios Japan is currently engaging in creating a professional dance program GenX. We currently have a yearly kids program KiNEXTionS and are looking forward to creating more versatile programs to fit everyones needs.</p>
        </div>
        <div className='about_info'>
          <h3>Meet The Staff</h3>
          <p>The staff at NXGN NY Studios Japan is dedicated to ensuring all your needs are met in a timely and professional manner. <Link to='/staff'>Our staff</Link> is dedicated to the lively hood of our studio and ensuring you recieve the best service available.</p>
        </div>
        <div className='about_info'>
          <h3>Meet The Faculty</h3>
          <p>Here at NXGN NY Studios Japan we have over 12 professional dance instructors dedicated to ensuring you are recieving the best developement for the type of dance you desire to learn. Our teachers are active members of the dance community and share their knowledge and experiences in the classroom. You can find individual informtion about each teacher on the <Link to='/staff'>instructor page</Link> or located on the instructor page of the studio booklet available at the front desk.</p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;