// Dependencies 
import React from 'react';

// Components
import ManagementCard from '../components/Staff/ManagementCard';

// Data
import { Managers } from '../data/ManagementData'

// Style
import '../styles/ManagementView.css';

const ManagementList = function() {
  return (
    <div className='staff_container'>
      <div className='staff_topSect'>
        <h2 className='staff_title'> 講師 NXGN TEACHERS </h2>
        <p className='staff_pageDesc'>世界で活躍するシーボが認めた、ネクスト・ジェネレーションが誇る素晴らしい講師陣です！ストリートダンスからジャズまで様々なジャンルで活躍中の講師達のレッスンは楽しくて為になりますよ♫ <br/>
          <br/>出演依頼、振付、プライベートレッスンなども受け付けています。
        </p>
      </div>
      {<div className='staff_grid'>
          {Managers && Managers.map(e => {
            return <ManagementCard manager = {e} key = {e.id}/>
          })}
        </div>
      }
    </div>
  );
};

export default ManagementList;