// Dependencies
import React from 'react';

// Styling
import './topBtn.css';

export const TopBtn = React.forwardRef((props, buttonRef) => {

  const toTheTop = e => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return(
    <div onClick={toTheTop} ref={buttonRef} className='the_Top'>
      <p>&#11165;</p>
      <p>Top <br /><span>トップ</span></p>
      <p>&#11165;</p>
    </div>
  );
});