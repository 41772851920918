// Dependency
import axios from 'axios';
const domain = process.env.REACT_APP_DOMAIN || 'https://nxgn-studios.herokuapp.com';

// Set Base for all API calls in this file
const styleAPI = axios.create({
  baseURL: domain,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

// Get All
export const GetStyles = async () => {
  try {
    const data = await styleAPI.get('/api/style')
    .then(res => {
      return res.data;
    })
    return data;
  }
  catch(err) {
    console.log(err);
  }
};

// Get By ID
export const GetStyle = async (id) => {
  try {
    const data = await styleAPI.get(`/api/style/${id}`)
    .then(res => {
      return res.data;
    })
    return data;
  }
  catch(err) {
    console.log(err);
  }
};


export default { GetStyles, GetStyle };