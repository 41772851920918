// Dependencies
import React, { useState } from 'react';

// Component
import Payment from '../modals/PayForm';

// Style
import '../../styles/SchedBlock.css';

const SchedBlock = ({klass}) => {
  let [show, setShow] = useState(false); // default is set to hide
  let [modal, setModal] = useState({title:'', time: '', end: ''}); // event info for modal

  // Toggle handler
  const toggleEvent = e => {
    // If show is false add the data before opening the modal, else close the modal without changing the data
    if(!show){ setModal(e) }
    setShow(!show);
  }

  return(
    <div className='ins_schedContainer'>
      <div className='ins_schedInfo'>
        <h3>{klass.title}</h3>
        <p>${klass.price}</p>
        <p>{klass.day} {klass.time}-{klass.end}</p>
        <button className='ins_btn' onClick={(event => toggleEvent(klass))}>BOOK!</button>
      </div>
      <Payment handleClose={toggleEvent} show={show} classInfo={modal} />
    </div>
  );
};

export default SchedBlock;