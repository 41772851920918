// Dependencies
import React from 'react';

// Style 
import '../styles/DirectionsPage.css';


const Direction = () => {
  
  return (
    <div className='directions_container'>
      <div className="directions_content">

        {/* Instructions Section */}

        {/* <h2>Directions to our Studio</h2> */}
        <h2>アクセス</h2>
        <div className="divider"/>
        <div className='directions_list'>
          <p><span className='bold'>※</span> イオン岡山から徒歩5分。</p>
          <p><span className='bold'>※</span> 駐車場はありませんので、周辺のコインパーキングなどをご利用ください。</p>
          <p><span className='bold'>※</span> 岡山駅方面·イオン岡山から県庁通りを西川に向かってまっすぐ進みます。<br />左側にあるセブンイレブン過ぎて、西川の手前の右側、<br />2階に友沢歯科が入っているビルの3階です。</p>
          <p><span className='bold'>※</span> ビル全体が歯科の雰囲気で入り難い感じがしますが、スタジオ内はカジュアルなので、<br />お気軽にお越しください(^^</p>
        </div>

        {/* Address Section */}

        <div className="directions_info">
          <div>
            {/* <h3>Address</h3> */}
            <h3>アドレス</h3>
            <div className="divider"/>
            {/* <p>NXGN NY Studios Japan<br />
              3-9 Saiwaichou Kita-Ku<br />                                                

              Daini Tomozawa Build 3Fl<br />
              Okayama, JP 700-0904
            </p> */}
            <p>Next Generation New York Studios<br />
              〒700-0903<br />
              岡山県岡山市北区幸町3-9 第二友澤ビル3階
            </p>
          </div>
          <a href='https://goo.gl/maps/9KXRVeAqmCBZMfHZA' target='_blank' rel='noopener noreferrer'><img className="img-box-large section" alt="" src="./assets/map.png" /></a>
        </div>
      </div>
    </div>
  );
};

export default Direction;