// Dependencies
import React, { useState } from 'react'
import axios from 'axios'

// Style
import '../styles/AdminPage.css'


const Admin = (props) => {
  let [user , setUser] = useState({ account: '', password: '', showPassword: false})
  console.log(props)
  const handleChanges = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  }
  const handleClickShowPassword = () => {
    setUser({ ...user, showPassword: !user.showPassword });
  };
  const handleSubmit = e => {
    e.preventDefault()
    if( user.account && user.password){
      axios
      .post('http://localhost:5400/api/auth/login', user)
      .then( res => {
        console.log(res)
        props.history.push('/')
        localStorage.setItem('token', res.data.localToken)
      })
      .catch( err => {
        console.log(err)
      })
    }
  }

  console.log(user)
  return (
    <div className="admin_container">
      <form onSubmit={handleSubmit} className='admin_form'>
        <div className='admin_input'>
          <label>User Name</label>
          <input className='form_name' name='account' value={user.account} onChange={handleChanges} type='text'/>
        </div>
        <div className='admin_input'>
          <label>Password</label>
          <input className='form_password' name='password' value={user.password} onChange={handleChanges} type={user.showPassword ? 'text' : 'password'}/>
          <img onClick={handleClickShowPassword} src='' alt=''/>
        </div>
        <input type='submit' value='Log In'/>
      </form>
    </div>
  )
}

export default Admin;