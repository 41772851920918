// Dependencies
import React from 'react';
import { NavLink } from 'react-router-dom';

// Asset
import Logo from '../../assets/LOGO.png';
import Info from '../../assets/nav-info.jpg'

//  Style
import './NavBar.css';

const NavBar = React.forwardRef((props, navRef) => {
  // let navHome = React.createRef();
  // let navSched = React.createRef();
  // let navStaff = React.createRef();

  // const homeOpen = e => {
  //   navHome.current.classList.toggle('navShow');
  //   navSched.current.classList.remove('navShow');
  //   navStaff.current.classList.remove('navShow');
  // }

  // const schedOpen = e => {
  //   navSched.current.classList.toggle('navShow');
  //   navHome.current.classList.remove('navShow');
  //   navStaff.current.classList.remove('navShow');
  // }

  // const staffOpen = e => {
  //   navStaff.current.classList.toggle('navShow');
  //   navSched.current.classList.remove('navShow');
  //   navHome.current.classList.remove('navShow');
  // }

  // const closeAll = e => {
  //   navStaff.current.classList.remove('navShow');
  //   navSched.current.classList.remove('navShow');
  //   navHome.current.classList.remove('navShow');
  // }

  return(
    <div className='navContainer' ref={navRef}>
      <div className='navLeft'>
        <NavLink to='/'><img src={Logo} alt='Logo'></img></NavLink>
        <p>NXGN NY STUDIOS JP MUSIC FEELING DANCE</p>
      </div>
      
      <div className='navRight'>
        <nav className='navLinks'>
          {/*  <div className='navDropDownCol'>
            <p onClick={homeOpen}>HOME<br/><span className='navJapanese'>トープページ</span></p>
            <div className='navDropDown' ref={navHome}>
              <NavLink to='/about' onClick={closeAll}>ABOUT NXGN<br/><span className='navJapanese'>スタジオ案内</span></NavLink> 
              <NavLink to='/kinextions' onClick={closeAll}>KiNEXTionS</NavLink>
              <NavLink to='/style' onClick={closeAll}>DANCE STYLES<br/><span className='navJapanese'>踊るのジャンル</span></NavLink>
              <NavLink to='/level' onClick={closeAll}>CLASS LEVELS<br/><span className='navJapanese'>クラス段</span></NavLink>
            </div>
          </div>
          <div className='navDropDownCol'>
            <p onClick={schedOpen}>SCHEDULE<br/><span className='navJapanese'>スケジュール</span></p>
            <div className='navDropDown' ref={navSched}>
              <NavLink to='/schedule' onClick={closeAll}>CLASS SCHEDULE<br/><span className='navJapanese'>クラススケジュール</span></NavLink> 
              <NavLink to='/events' onClick={closeAll}>EVENTS<br/><span className='navJapanese'>エベント</span></NavLink>
              <NavLink to='/workshops' onClick={closeAll}>WORKSHOPS<br/><span className='navJapanese'>イベント</span></NavLink>
            </div>
          </div>
          <div className='navDropDownCol'>
            <p onClick={staffOpen}>STAFF<br/><span className='navJapanese'>スタフィー</span></p>
            <div className='navDropDown' ref={navStaff}>
              <NavLink to='/staff' onClick={closeAll}>ALL STAFF<br/><span className='navJapanese'></span></NavLink> 
              <NavLink to='/administration' onClick={closeAll}>MANAGEMENT<br/><span className='navJapanese'>運営</span></NavLink>
              <NavLink to='/instructors' onClick={closeAll}>INSTRUCTORS<br/><span className='navJapanese'>講師紹介</span></NavLink>
            </div>
          </div> */}
          {/* <div className='navDropDownCol' > 
            <NavLink to='/services' onClick={closeAll}>FEES<br/><span className='navJapanese'>手数料</span></NavLink>
            </div>
            <div className='navDropDownCol' > 
            <NavLink to='/contact' onClick={closeAll}>CONTACT<br/><span className='navJapanese'>お問い合わせ</span></NavLink>
          </div> */}
          <NavLink to='/' >HOME<br/><span className='navJapanese'>トープページ</span></NavLink>
          <NavLink to='/schedule'>SCHEDULE<br/><span className='navJapanese'>クラススケジュール</span></NavLink>
          <NavLink to='/staff'>STAFF<br/><span className='navJapanese'>講師紹介</span></NavLink>
          <NavLink to='/services'>FEES<br/><span className='navJapanese'>料金</span></NavLink>
          {/* <NavLink to='/about'>ABOUT NXGN<br/><span className='navJapanese'>スタジオ案内</span></NavLink>  */}
        </nav>

        {/* Studio Information */}
        <div className='navInfo'>
          <img src={Info} alt='contact info for the studio' />
          <div className='navInfoRight'>
            <NavLink to='/contact'>CONTACT<br/><span className='navJapanese'>お問い合わせ</span></NavLink>
            <NavLink to='/directions'>ACCESS<br/><span className='navJapanese'>アクセス</span></NavLink>
          </div>
        </div>
      </div>
    </div>
  )
})

export default NavBar;