// Dependencies 
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';

// Component
import { GetOneStaff, GetClasses } from '../services/StaffService';
import SchedBlock from '../components/Staff/ScheduleBlock';

// Style
import '../styles/IProfile.css';

const InsProfile = function(props){
  // let [edit, setEdit] = useState(false)
  // let [admin, setAdmin] = useState(false)
  let [staff, setStaff] = useState();
  let [schedule, setSchedule] = useState();
  let [loading, setLoading] = useState(true);

  useEffect( () => { 
    const grabBoth = async() => {
      const ins = await GetOneStaff(props.match.params.id);
      const sched = await GetClasses(props.match.params.id);
      setStaff(ins);
      setSchedule(sched);
      setLoading(false);
    };
    const grabOne = async() => {
      const sched = await GetClasses(staff.id);
      setSchedule(sched);
      setLoading(false);
    };

    if(props.location.state) setStaff(props.location.state.ins)

    staff ? grabOne() : grabBoth()
  }, []);

  // console.log(props)
  // console.log(staff, schedule)

  return (
    <>
      {loading 
        ? (<div className='staff_spinner'>
            <Loader type='Bars' color='#000000' height={200} width={200} timeout={5000}/>
          </div>
        )
        : (<div className='ins_container'>
          <div className='ins_topSect'>
            <div className='ins_details'>
              <h2>{staff.fName}</h2>
              <h3>{staff.style}</h3>
              <a href={`https://www.instagram.com/${staff.socialMedia}`}><h3>@{staff.socialMedia}</h3></a>
              <p>{staff.bio}</p>
            </div>

            <div className='ins_right'>
              <img src={`.${staff.pic}`} alt={staff.fName}/>
              <p>&#11167; &#11167; CLASS SCHEDULE &#11167; &#11167;</p>
            </div>
          </div>

          <div className='ins_schedule'>
            {schedule && schedule.map(e => {
              return <SchedBlock klass={e} key={e.id} />
            })}
          </div>
        </div>)
      }
    </>
  );
};

export default InsProfile;