// Dependencies 
import React from 'react';
// import { Link } from 'react-router-dom';

// Style
import '../styles/Landing.css';

// Assets
import LandingImage from '../assets/image-asset.png'

const Landing = () => {
  return (
    <div className='landing_container' >
      <div className='landing_bg'>
        {/* <Link to='/contact'>「パーティー参加登録」</Link> */}
        <div className='landing_info'>
          <>
            <img src={LandingImage} alt='compass'/>
            <h1>NXGN NY JP</h1>
          </>
          <>
            <h3>NEW YORK, JAPAN</h3>
            <h2>2005-2020</h2>
            <h2>15 Years</h2>
          </>
        </div>
      </div>
    </div>
  );
};

export default Landing;