// Dependencies
import React from 'react';
import { Route } from 'react-router-dom';

// Components
import Schedule from '../views/Schedule';
import InsProfile from '../views/InstructorProfile';
import StaffList from '../views/StaffView';
import MgmtProfile from '../views/ManagementProfile';
import ManagementList from '../views/ManagementView';
import Admin from '../auth/AdminLogin';
import Landing from '../views/LandingPage';
import Contact from '../views/ContactPage';
import Directions from '../views/DirectionsPage';
import FeePage from "../views/FeePage";
import StyleView from "../views/StyleView";
import AboutPage from "../views/AboutPage";


const Routes = () => {
  return (
    <>
      <Route exact path="/" component={Landing} />
      <Route path="/about" component={AboutPage} />
      {/* <Route path="/style" component={StyleView} /> */}
      <Route path="/schedule" component={Schedule} />
      <Route path="/admin" component={Admin} />
      <Route path="/services" component={FeePage} />
      <Route path="/contact" component={Contact} />
      <Route path="/directions" component={Directions} />
      <Route exact path="/staff" component={StaffList} />
      <Route path="/staff/:id" component={InsProfile} />
      <Route exact path="/mgmt" component={ManagementList} />
      <Route path="/mgmt/:id" component={MgmtProfile} />
    </>
  );
};


export default Routes;